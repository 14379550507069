import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import Button from '../../../components/Button/Button';
import { deleteGallery } from '../../../services/gallerieServices';
import EditAlbumModal from './EditAlbumModal';
import { toast } from 'react-toastify';
// Import css files for slick carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const GalleryCard = ({ gallery, index, userRole, refreshGalleriesList }) => {
  const navigate = useNavigate();
  const defaultImage = "/img/no-image.jpg";
  const [editModalOpen, setEditModalOpen] = useState(false);

  const settings = {
    dots: gallery.photos.length > 1,
    infinite: gallery.photos.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: gallery.photos.length > 1,
    autoplaySpeed: 3000,
    arrows: false
  };

  const handleViewAlbumClick = () => {
    navigate(`/galerie-photos/album/${gallery.id}`);
  };

const handleDeleteGallery = async () => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette galerie ?")) {
      try {
        await deleteGallery(gallery.id);
        toast.success("Galerie supprimée avec succès.");
        refreshGalleriesList(); // Call to refresh the galleries list after deletion
      } catch (error) {
        console.error("Erreur lors de la suppression de la galerie:", error);
        toast.error("Erreur lors de la suppression de la galerie.");
      }
    }
  };

  // Determine if the layout should be reversed based on the index
  const isReversed = index % 2 === 1;

  return (
    <div className={`flex flex-col ${isReversed ? 'md:flex-row-reverse' : 'md:flex-row'} mb-6 shadow-lg bg-slate-200 dark:bg-slate-400 rounded-lg overflow-hidden`}>
      <div className="md:w-1/2">
        {gallery.photos && gallery.photos.length > 0 ? (
          gallery.photos.length > 1 ? (
            <Slider {...settings}>
              {gallery.photos.map((photo, idx) => (
                <div key={idx} className="w-full h-full">
                  <img src={photo.photo_url} alt={`Slide ${idx}`} className="object-cover w-full h-80" />
                </div>
              ))}
            </Slider>
          ) : (
            <img src={gallery.photos[0].photo_url} alt="Gallery cover" className="object-cover w-full h-80" />
          )
        ) : (
          <img src={defaultImage} alt="Default gallery cover" className="object-cover w-full h-80" />
        )}
      </div>
      <div className="md:w-1/2 p-4 flex flex-col justify-between">
        <div>
          <h3 className="text-gray-600 font-bold text-xl dark:text-white">{gallery.titre}</h3>
          <p className="text-gray-600 mt-2 mb-4 dark:text-slate-200">{gallery.description}</p>
        </div>
        <div className='flex flex-row gap-4 justify-center'>
          <Button text="Voir Album" onClick={handleViewAlbumClick} />
          {(userRole === 'admin' || userRole === 'enseignant') && (
            <>
              <Button text="Supprimer" onClick={handleDeleteGallery} className="bg-red-500" />
              <Button text="Éditer l'Album" onClick={() => setEditModalOpen(true)} className="bg-blue-500" />
            </>
          )}
        </div>
      </div>
      {editModalOpen && (
        <EditAlbumModal
          gallery={gallery}
          isOpen={editModalOpen}
          onClose={() => setEditModalOpen(false)}
          refreshList={refreshGalleriesList}
        />
      )}
    </div>
  );
};

export default GalleryCard;
